* {
  font-family: Roboto;
  font-style: normal;
}

section {
  width: 100%;
}

.green-letters {
  color: rgb(84, 194, 120);
}

.justify-center-item {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.text-center {
  text-align: center;
}

.left-float {
  float: left;
}

.no-margin-padding {
  margin: 0;
  padding: 0;
}

.not-wrap {
  white-space: nowrap;
}

.general-button-style {
  background: #54c278;
  border-radius: 58px;
  z-index: 3;
  text-decoration: none;
}

.entire-page {
  height: calc(100vh - 80px);
}

@media screen and (max-width: 960px) {
  .entire-page {
    height: auto;
  }
}
