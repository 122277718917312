@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  overflow: hidden;
  overflow-y: auto;
  width: 100vw;
}


.testimonial {
  padding: 56px 0 56px 0;
}

.position-abs {
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(0, -50%);
}

.position-rel {
  position: relative;
}
.static-height {
  height: 82px;
}

.static-height1 {
  height: 121px;
}

.dynamic-picture {
  width: 40%;
}

.home-first-part {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.full-width {
  width: 100%;
}

.align-text-center {
  margin: auto;
  padding: 0;
}

.big-title1 {
  font-size: 50px;
  line-height: 59px;
  color: black;
}
.MuiButton-root:hover {
  color: black;
}

.small-title1 {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-top: 30px;
}

.description-cap,
.description-small {
  position: absolute;
  text-align: center;
}

.description-cap {
  width: 457px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  z-index: 4;
}

.description-small {
  width: 211px;
  height: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  z-index: 4;
}

.service-bkg-color,
#contact-form {
  background-color: #f3fff7;
  padding: 80px 0 80px 0;
}

.bkg-dots,
.bkg-dots-1,
.bkg-dots-2 {
  position: absolute;
  opacity: 0.09;
  transform: rotate(-34.65deg);
}

.bkg-dots {
  width: 466px;
  height: 297.03px;
  left: 20px;
  bottom: 10%;
  z-index: 1;
}

.bkg-dots-1 {
  width: 378.74px;
  height: 241.4px;
  left: -5%;
  top: 85%;
}

.bkg-dots-2 {
  width: 302.51px;
  height: 192.82px;
  left: 80%;
  top: 70%;
  z-index: 1;
}

.bkg-dots-3 {
  position: absolute;
  width: 302.51px;
  height: 192.82px;
  opacity: 0.09;
  top: 50%;
  left: 80%;
  transform: rotate(29.31deg);
}

.bkg-dot-circle {
  position: absolute;
  width: 595.28px;
  height: 595.28px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  padding: 80px;
}

.testimonial-circle {
  position: absolute;
  width: 603px;
  height: 603px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.margin-left-240 {
  margin-left: 240px;
}

.bkg-dot-check {
  max-width: 100%;
}

.margin-100 {
  margin: 100px auto 100px auto;
}

.flex-container-not-center {
  display: flex;
  align-items: center;
  flex-flow: row;
}

.flex-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
}

#home .flex-container-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row;
}

#home .flex-container-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.margin-40-100 {
  margin: 0 40px 100px 0;
}

.dynamic-data-text {
  width: 28%;
}

.margin-bottom-14 {
  margin-bottom: 14px;
}

.general-caption {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}

.sub-desc,
.sub-desc1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.productivity-text {
  width: 58%;
  text-align: center;
}

.productivity-picture-show {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row;
}

.padding-56 {
  padding: 56px 0 56px 0;
}

.common-size {
  width: 28%;
  z-index: 3;
}

.give-padding {
  padding: 220px 0 220px 0;
}

.solution-text {
  width: 731px;
  text-align: center;
  margin-bottom: 25px;
  z-index: 3;
}

.common-button-style {
  background: #54c278;
  border-radius: 58px;
  padding: 20px 62px 20px 62px;
  z-index: 3;
  text-decoration: none;
}

.margin-right-120 {
  margin-right: 120px;
}

.common-button-font-style {
  color: white;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}

.button-group {
  margin-top: 11px;
}

.button-group .common-button-style {
  background: #54c278;
  border-radius: 58px;
  padding: 10px 62px 10px 62px;
  border: none;
  z-index: 3;
  text-decoration: none;
}

.common-home-service-style {
  width: 225px;
  color: black;
  text-align: center;
}

.common-home-service-caption {
  font-weight: 900;
  font-size: 80px;
  /* line-height: 94px; */
}

.common-home-service-caption-small-letter {
  font-weight: 900;
  font-size: 32px;
  line-height: 94px;
}

.common-home-service-sub-caption {
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
}

.common-home-service-dec {
  font-size: 18px;
  line-height: 21px;
}

.common-home-service-text {
  margin-top: 12px;
}

.service-common-margin {
  margin-top: 21px;
}

.cycle-picture {
  order: 1;
  width: 60%;
  margin-left: 10%;
}

.cycle-text {
  width: 30%;
  color: black;
}

.cycle-caption {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}

.cycle-desc {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.margin-left-minus-80 {
  margin-left: -10%;
}

.cycle-text-order {
  order: 2;
}

.common-quote-style {
  text-align: center;
  width: 288px;
}

.quote-form {
  font-family: Alegreya;
  font-size: 160px;
  line-height: 218px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.client-testimonial {
  width: 217px;
  height: 94px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: auto;
}

.client-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.client-photo {
  padding-top: 30px;
}

.testimonial-height {
  height: 600px;
}

.introduce-text {
  width: 762px;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
}

@media (max-width: 767px) {
  .introduce-text {
    width: 80%;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }
}

.google-picture-style {
  margin-right: 130px;
}

.solve-text {
  height: 86px;
  width: 762px;
  margin: auto;
}

.solution-text-style {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin: 0;
}

.solution-dec {
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.padding-110 {
  padding: 110px 0 110px 0;
}

@media screen and (max-width: 786px) {
  .position-abs {
    left: 20%;
  }
  .static-height1 {
    height: auto;
  }
  .container {
    position: relative;
    margin: auto;
    width: 100%;
  }

  .flex-container-center {
    flex-direction: column;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
  .description-cap {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    width: 230px;
  }

  .description-small {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    width: 211px;
    margin-top: 40px;
  }

  .bkg-dots {
    display: none;
  }

  .margin-100 {
    margin: 50px 0 50px 0;
  }

  .margin-40-100 {
    margin: 0 0 8px 0;
  }

  .margin-14 {
    margin-bottom: 10px;
  }

  .dynamic-data-text {
    width: 300px;
    text-align: left;
  }

  .general-caption {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
  }

  .sub-desc {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
    width: 216px;
    height: 97px;
  }

  .sub-desc1 {
    width: 270px;
    height: 97px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
    margin: auto;
  }

  .dynamic-picture {
    max-width: 60%;
    z-index: 2;
  }

  .bkg-dots-1 {
    width: 476.05px;
    height: 303.43px;
    opacity: 0.06;
    transform: rotate(-34.65deg);
    top: 20%;
    left: 30%;
  }

  .productivity-picture-show {
    flex-flow: column;
  }

  .productivity-picture-show > img {
    margin-bottom: 40px;
  }

  .padding-56 {
    padding: 0;
  }

  .bkg-dots-2 {
    display: none;
  }

  .caption-size {
    width: 230px;
    height: 54px;
    margin: auto;
  }

  .solve-text {
    width: auto;
    height: auto;
  }

  .solution-text .sub-desc {
    width: 336px;
    height: 97px;
    font-weight: 500;
    font-weight: 500;
    font-size: 1rem;
    margin: auto;
  }

  .margin-right-120 {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .common-home-service-style > img {
    display: none;
  }

  #home .flex-container-row {
    justify-content: center;
    flex-flow: column;
    align-items: center;
  }

  .common-home-service-style {
    margin-bottom: 50px;
  }

  .cycle-picture {
    order: 2;
  }

  .cycle-text-order {
    order: 1;
  }

  .cycle-text {
    width: 60%;
  }

  .cycle-picture {
    width: 100%;
  }

  .flex-container-not-center {
    flex-flow: column !important;
    justify-content: center !important;
  }

  .cycle-caption {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-top: 50px;
  }

  .cycle-desc {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
  }

  .margin-left-minus-80 {
    margin-left: 0;
  }

  .cycle-picture {
    max-width: 90%;
  }

  .bkg-dots-3 {
    width: 100%;
    /* height: 138.07px; */
    opacity: 0.09;
    transform: matrix(-0.87, -0.49, -0.49, 0.87, 0, 0);
    top: 10%;
    left: -30%;
  }

  .testimonial {
    background: #f3fff7;
  }

  .testimonial-circle {
    display: none;
  }

  .testimonial-height {
    height: auto;
  }

  .common-quote-style {
    margin-top: 50px;
  }

  .margin-bottom-50 {
    margin-bottom: 30px;
  }

  .google-picture-style {
    margin-right: 0;
    width: 50%;
  }

  .microsoft-picture-style {
    width: 80%;
  }

  .button-group {
    margin-top: 20px;
  }

  .common-size {
    width: 80%;
  }

  .common-button-style {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 10px 60px 10px 60px;
  }

  .solution-text-style {
    font-size: 20px;
    line-height: 23px;
  }

  .solution-dec {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
  }

  .padding-110 {
    padding: 0 0 100px 0;
  }

  .give-padding {
    padding: 100px 0 100px 0;
  }

  #contact-form {
    background: white;
  }

  .big-title1 {
    font-size: 20px;
    line-height: 23px;
  }

  .small-title1 {
    font-size: 10px;
    line-height: 12px;
  }
}

@media screen and (min-width: 787px) {
  .position-abs {
    left: '30%';
  }
  .container {
    width: 100%;
    position: relative;
    margin: auto;
  }

  .mobile-one {
    display: none;
  }

  .desktop {
    display: block;
  }

  .description-cap {
    height: 94px;
  }

  .description-small {
    margin-top: 70px;
  }
}

@media screen and (min-width: 1280px) {
  .position-abs {
    left: 20%;
  }
  .container {
    width: 1280px;
    position: relative;
    margin: 10px auto 10px auto;
  }

  .mobile-one {
    display: none;
  }

  .desktop {
    display: block;
  }

  .description-cap {
    height: 94px;
  }

  .description-small {
    margin-top: 70px;
  }

}

.table30381-tr {
  display: none;
}

.table12466-tr {
  display: none;
}

.table7126-tr {
  display: none;
}

.table9310-tr {
  display: none;
}

.table22403-tr {
  display: none;
}

.table8510-tr {
  display: none;
}
.table6326 {
  display: none;
}
.table7352-tr {
  display: none;
}
.table11666-tr {
  display: none;
}

.table6326-tr {
  display: none;
}
/* for the td class with the class .table7013 */
td.table7013 {
  width: 200px;
}

td.table7932 {
  width: 200px;
}

td.table8024 {
  width: 300px;
}
td.table6732 {
  width: 185px;
}
td.table8152 {
  width: 185px;
}

td.table6730 {
  width: 300px;
}

td.table7308 {
  width: 200px;
}

td.table6594 {
  width: 300px;
}

td.table15945 {
  width: 300px;
}

td.table7243 {
  width: 200px;
}

td.table6596 {
  width: 300px;
}

td.table6105 {
  width: 250px;
}

td.table6298 {
  width: 250px;
}
td.table6399 {
  width: 200px;
}

td.table5932 {
  width: 200px;
}

td.table6906 {
  width: 300px;
}

td.table6400 {
  width: 200px;
}
td.table6362 {
  width: 300px;
}
td.table6335 {
  width: 300px;
}

td.table7016 {
  width: 300px;
}
  
  







